import { faXmark } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'

function MbNav({setNav}) {
  return (
   <div className="mb-nav main-bg fixed w-screen h-screen top-0 flex flex-col justify-center items-center gap-8">
    <FontAwesomeIcon className='white-txt' onClick={() => {setNav(false)}} icon={faXmark} />
    <a onClick={() => {setNav(false)}} className='white-txt bold' href="#inicio">Inicio</a>
    <a onClick={() => {setNav(false)}} className='white-txt bold' href="#mision">Misión</a>
    <a onClick={() => {setNav(false)}} className='white-txt bold' href="#servicios">Servicios</a>
    <a onClick={() => {setNav(false)}} className='white-txt bold' href="#contacto">Contacto</a>
   </div>
  )
}

export default MbNav