import React from "react";

function Input({ setState, type, label,  name, err }) {
  return (
    <div className="input-group">
      <input
        id={label}
        name={label}
        err = {err}
        required={true}
        type={type}
        autoComplete="off"
        onChange={(evt) => {
          setState(evt.target.value);
        }}
        className={`${err ? 'input-err' : 'input '}`}
      />
      <label htmlFor={label} className={`user-label ${err ? "user-label-err" : ''}`}>{err ? err : label}</label>
    </div>
  );
}

export default Input;