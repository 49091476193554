import React from "react";

function SecondBtn({title, to}) {
  return (
    <a href={to}>
      <button className="white-bg rounded bold main-txt py-4 px-6 mt-4 w-40">
        {title}
      </button>
    </a>
  );
}

export default SecondBtn;
