import React from 'react'

function Loader() {
  return (
   <div className="loaderContainer h-screen w-screen fixed top-0 white-bg flex justify-center items-center">
     <div className="loader">
    <div className="circle"></div>
    <div className="circle"></div>
    <div className="circle"></div>
    <div className="circle"></div>
</div>
   </div>

  )
}

export default Loader