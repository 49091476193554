import React from 'react'

function ServiceCard({title, description}) {
  return (
    <div className="serviceCard  w-[450px]" >
        <div className="top flex items-center overflow-hidden  h-fit ">
            <div className="cardTitle  h-full flex items-center">
                     <span className='main-txt h-fit bolder'>{title}</span>
            </div>
        </div>

        <div className="body light fs-16">
            <p>{description}</p>
        </div>
    </div>
  )
}

export default ServiceCard