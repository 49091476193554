import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";



function TopNav({setNav}) {


  return (
    <nav className="flex px-8 pt-8 fixed white-bg top-0 w-full">
      <FontAwesomeIcon className="hidden fs-24" onClick={() => {setNav(true)}} icon={faBars} />

      <div className="iconContainer">
      </div>
      <div className="w-full links flex justify-center gap-8">
        <a href="#inicio">Inicio</a>
       <a href="#mision"> Misión</a>
       <a href="#servicios"> Servicios</a>
        <a href="#contacto">Contacto</a>
      </div>
    </nav>
  );
}



export default TopNav;
