import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import TopNav from "../Components/TopNav";
import ServiceCard from "../Components/ServiceCard";
import Aos from "aos";
import "aos/dist/aos.css";
import { faAt, faPhone } from "@fortawesome/free-solid-svg-icons";
import Loader from "../Components/Loader";
import Footer from "../Components/Footer";
import ContactForm from "../Components/ContactForm";
import MbNav from "../Components/MbNav";
import SecondBtn from "../Components/ SecondBtn ";

function Main() {
  const [mbNav, setMbNav] = useState(false);

  useEffect(() => {
    Aos.init();
  }, []);

  useEffect(() => {
    document.querySelector(".loaderContainer").classList.add("hidden");
  }, []);

  return (
    <>
      <Loader />
      {mbNav && <MbNav setNav={setMbNav} />}
      <header>
        <TopNav setNav={setMbNav} />
      </header>
      <main className="">
        <section
          id="inicio"
          className="landing h-fit pr-14 mt-36 flex gap-4 h-screen"
        >
          <div className="left w-[50%] main-bg pl-md-12 pl-sm-4 pr-4 white-txt p-4" data-aos="fade-right">
            <div className="flex items-center gap-4 mb-8">
              <img src="./bell.png" className="icon  fit-cover " alt="" />
              <h1 className="bolder fs-28 leading-tight">
                Contador <br /> Que Declaro
              </h1>
            </div>
            <p className="main-bg white-txt  fs-18">
              Conocemos las dificultades que le conlleva a los micro y pequeños
              empresarios desarrollar un negocio, también la necesidad de
              conocer:
            </p>
            <p className="mt-4 fs-16 text-justify">
              Su visión, su misión, los requisitos legales, los objetivos, los
              presupuestos, la inversión de capital, el financiamiento, los
              costos operativos, el costo de inventario, los costos de materias
              primas, las importaciones, el desarrollo del mercadeo, la
              publicidad, los riesgos financieros, los flujos de caja, los
              sistemas de control, la competencia, la innovación y un
              crecimiento financiero rentable.
              <br /> <br />
              Porque sabemos esto y otras cosas más es que queremos ser su
              aliado estratégico, quienes damos el soporte que su negocio
              necesita. ContadorQueDeclaro.com es un grupo respaldado por
              profesionales en Contaduría Pública para asistirle en sus
              declaraciones tributarias, para asesorarle en Contaduría
              Financiera.
            </p>
            <SecondBtn to="#mision"  title="Misión" />
          </div>

          <div
            className="right  w-[50%] flex justify-center"
            data-aos="fade-left"
          >
            <img
              src="./INICIO.webp"
              className="w-full h-full fit-content"
              alt="imagen principal, personas en una oficina"
            />
          </div>
        </section>

        <section
          id="mision"
          className="objetivos main-bg py-12 h-fit flex gap-16 px-14"
          data-aos="fade-up"
        >
          <div
            className="left  w-1/2 flex justify-center"
            data-aos="fade-right"
          >
            <img
              src="./MISION.webp"
              className="w-full h-full rounded-2xl"
              alt="imagen principal, personas en una oficina"
            />
          </div>

          <div className="right w-1/2" data-aos="fade-left">
            <div className="title w-full flex gap-8 items-center">
              <img src="./Bell.png" alt="" className="icon" />
              {/* <FontAwesomeIcon className="big-txt white-txt" icon={faLightbulb} /> */}
              <h2 className="fs-24 bolder">
                Nuestra <br /> <h2 className="white-txt">Misión</h2>
              </h2>
            </div>

            <p className="fs-16 mt-8 white-txt">
              Ser su aliado estratégico, ayudarle a implementar un crecimiento
              ordenado y sostenible que le permita desarrollarse con
              tranquilidad y Libertad Financiera Contable, sabiendo que se
              encuentra en buenas manos. Estamos juntos en un encuentro de ideas
              inteligentes para plantear soluciones inteligentes a sus
              necesidades, únase a la Campana de la Libertad Financiera,
              solidarios para construir una Costa Rica mejor.
            </p>

            <a href={"#servicios"}>
              <button className="white-bg rounded main-txt bold py-4 px-6 mt-4 w-40">
                Servicios
              </button>
            </a>
          </div>
        </section>

        <section id="servicios" className="servicios h-fit   h-fit w-full">
          <div
            data-aos="fade-down"
            className="title relative flex justify-center items-center  w-full"
          >
            <div className="opacity absolute w-full h-full"></div>
            <div className="w-full absolute flex flex-col items-center justify-center h-full">
              <div className="flex items-center">
                <img src="./bell.png" alt="" className="icon " />

                <span className="big-txt white-txt bolder">Servicios</span>
              </div>
              <h2 className=" white-txt bold light px-4 text-center mb-fs-12">
                Somos su herramienta externa respaldada por un grupo de
                profesionales en Contaduría Pública
              </h2>
            </div>
          </div>

          <div className="serviceCardsContainer h-fit px-14 w-full mt-14 flex flex-wrap mb-flex-col gap-12">
            <div
              className="flex cardGroup flex-col gap-4 h-full"
              data-aos="zoom-in"
            >
              <ServiceCard
                title={"Nuestros servicios contables"}
                description={
                  "Están respaldados por un grupo de profesionales en Contaduría Pública"
                }
              />
              <ServiceCard
                title={"Entorno empresarial"}
                description={
                  "Le asesoramos en los factores internos y externos que inflyen en la economía de su empresa"
                }
              />
              <ServiceCard
                title={"Servicios Outsourcing"}
                description={
                  "Somos una herramienta externa a la medida de sus necesidades"
                }
              />

              <div className="serviceCard  w-[450px]">
                <div className="top flex items-center overflow-hidden  h-fit ">
                  <div className="cardTitle  h-full flex items-center">
                    <span className="main-txt h-fit bolder">
                      Certificaciones de:
                    </span>
                  </div>
                </div>

                <div className="body  light fs-16">
                  <ul>
                    <li>Estados Financieros</li>
                    <li>Flujos de caja proyectados</li>
                    <li>Estudios especiales</li>
                    <li>Estudios de factibilidad</li>
                  </ul>
                </div>
              </div>
            </div>
            <div
              className="flex cardGroup flex-col gap-4 h-full"
              data-aos="zoom-in"
            >
              <ServiceCard
                title={"Trámites ante la Administración Tributaria"}
                description={
                  "Le asistimos en los procesos de inscripción como contribuyente y en sus declaraciones fiscales"
                }
              />
              <ServiceCard
                title={"Declaración del Impuesto del Valor Agregado D104-2"}
                description={
                  "En su delaración del impuesto al IVA nos aseguramos de que obtenga sus créditos fiscales"
                }
              />
              <ServiceCard
                title={"Declaración de Rentas del Capital Inmobiliario D125"}
                description={
                  "Le ayudamos en su declaración de ingresos de Propiedad de Bienes Inmuebles"
                }
              />
            </div>

            <div
              className="flex flex-col cardGroup gap-4 h-full"
              data-aos="zoom-in"
            >
              <ServiceCard
                title={"Declaración del Impuesto a la Renta D101"}
                description={
                  "Le asistimos en su declaración del impuesto a la renta, nos aseguramos de que obtenga sus créditos fiscales"
                }
              />

              <ServiceCard
                title={"Declaraciones Sociedades Inactivas D195"}
                description={
                  "Realizamos sus declaraciones de sociedades inactivas."
                }
              />

              <ServiceCard
                title={
                  "Declaración del Registro de Transparencia y Beneficiarios Finales (RTBF)"
                }
                description={
                  "Le asistimos en su declaración ante el Banco Central de Costa Rica"
                }
              />
            </div>
          </div>
        </section>

        <section
          id="contacto"
          className="contacto h-fit mt-32 flex flex-col items-center justify-center "
        >
          <ContactForm />

          <div
            data-aos="fade-right"
            className="contactCards main-bg w-full flex flex-wrap justify-center gap-12 mt-16"
          >
            <div className="contactCard main-bg p-8 w-fit flex items-center flex-col gap-2 rounded-xl">
              <FontAwesomeIcon className="white-txt big-txt" icon={faPhone} />
              <p className="white-txt bold">(506) 8454-0000</p>
            </div>

            <div className="contactCard main-bg p-8 w-fit flex items-center flex-col gap-2 rounded-xl">
              <FontAwesomeIcon className="white-txt big-txt" icon={faAt} />
              <p className="white-txt fs-12 bold">
                contacto@contadorquedeclaro.com
              </p>
            </div>

            <a title="WhatsApp" className="white-txt bold fs-13" href=" https://wa.me/+50684540000" target="_blank">
              <div className="contactCard main-bg p-8 w-fit flex items-center flex-col gap-2 rounded-xl">
                <FontAwesomeIcon
                  className="white-txt big-txt"
                  icon={faWhatsapp}
                />
                Ir a Whatsapp
              </div>
            </a>
          </div>
        </section>
      </main>

      <Footer />
    </>
  );
}

export default Main;
